<template>
  <div class="dabox">
    <!-- 文字标识 -->
    <div class="dingwei1">
      <p class="wenzi wenzi1">PIC-0701</p>
      <p class="wenzi wenzi2">LI-S103</p>
      <p class="wenzi wenzi3">LI-S104</p>
      <p class="wenzi wenzi4">JRCA-0701A</p>
      <p class="wenzi wenzi5">JRCA-0701B</p>
      <p
        class="shuzi shuzi1"
        @click="toCompon(2, 'PT12', 'DB1S', 'PT12_GZQ', '流化床离心机进料压力')"
        @dblclick="Cclick(infoList.DB1S.PT12, 'PT12', 'DB1S', 'PT12_GZQ')"
      >
        {{ infoList.DB1S.PT12 || "0.00" }} Mpa
      </p>
      <p
        class="shuzi shuzi2"
        @click="
          toCompon(
            2,
            'VP16',
            'DB1S',
            'VP16_GZQ',
            '阀门开度--流化床离心机进料回流阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP16, 'VP16', 'DB1S', 'VP16_GZQ')"
      >
        {{ infoList.DB1S.VP16 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi3"
        @click="toCompon(2, 'LT01', 'DB1S', 'LT01_GZQ', 'TK-2G浆料罐料位')"
        @dblclick="Cclick(infoList.DB1S.LT01, 'LT01', 'DB1S', 'LT01_GZQ')"
      >
        {{ infoList.DB1S.LT01 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi4"
        @click="toCompon(2, 'LT02', 'DB1S', 'LT02_GZQ', 'TK-4G浆料罐料位')"
        @dblclick="Cclick(infoList.DB1S.LT02, 'LT02', 'DB1S', 'LT02_GZQ')"
      >
        {{ infoList.DB1S.LT02 || "0.00" }} %
      </p>
      <!-- <p class="shuzi shuzi5">42.08 %</p>
      <p class="shuzi fen shuzi6">100.27 L</p> -->
      <p
        class="shuzi shuzi7"
        @click="
          toCompon(
            2,
            'VP03',
            'DB1S',
            'VP03_GZQ',
            '阀门开度--流化床1#离心机进料阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP03, 'VP03', 'DB1S', 'VP03_GZQ')"
      >
        {{ infoList.DB1S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi8"
        @click="
          toCompon(2, 'NI03', 'DB1S', 'NI03_GZQ', '流化床1#离心机进料功率')
        "
        @dblclick="Cclick(infoList.DB1S.NI03, 'NI03', 'DB1S', 'NI03_GZQ')"
      >
        {{ infoList.DB1S.NI03 || "0.00" }} KW
      </p>
      <p
        class="shuzi shuzi9"
        @click="
          toCompon(
            2,
            'VP04',
            'DB1S',
            'VP04_GZQ',
            '阀门开度--流化床2#离心机进料阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP04, 'VP04', 'DB1S', 'VP04_GZQ')"
      >
        {{ infoList.DB1S.VP04 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi10"
        @click="
          toCompon(2, 'NI04', 'DB1S', 'NI04_GZQ', '流化床2#离心机进料功率')
        "
        @dblclick="Cclick(infoList.DB1S.NI04, 'NI04', 'DB1S', 'NI04_GZQ')"
      >
        {{ infoList.DB1S.NI04 || "0.00" }} KW
      </p>
      <p class="biaoshi biaoshi1">TK-2G</p>
      <div
        class="caozuo caozuo1"
        :style="{
          background:
            (infoList.DB1M__p__XK03 && infoList.DB1M__p__XK03.RM == 1) ||
            (infoList.DB1M__p__XK03 && infoList.DB1M__p__XK03.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK03
            ? toDetail(1, 'XK03', 'DB1M__p__XK03', '', 'XK03运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo2"
        :style="{
          background:
            infoList.DB1M__p__MAN03 && infoList.DB1M__p__MAN03.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN03
            ? toCompon(0, 'AV', 'DB1M__p__MAN03', 'MAN03_RM_GZQ', '备用')
            : ''
        "
      >
        A
      </div>
      <div
        class="caozuo caozuo3"
        :style="{
          background:
            (infoList.DB1M__p__XK04 && infoList.DB1M__p__XK04.RM == 1) ||
            (infoList.DB1M__p__XK04 && infoList.DB1M__p__XK04.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK04
            ? toDetail(1, 'XK04', 'DB1M__p__XK04', '', 'XK04运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo4"
        :style="{
          background:
            infoList.DB1M__p__MAN04 && infoList.DB1M__p__MAN04.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN04
            ? toCompon(0, 'AV', 'DB1M__p__MAN04', 'MAN04_RM_GZQ', '备用')
            : ''
        "
      >
        A
      </div>
      <div
        class="caozuo caozuo5"
        :style="{
          background:
            (infoList.DB1M__p__XK05 && infoList.DB1M__p__XK05.RM == 1) ||
            (infoList.DB1M__p__XK05 && infoList.DB1M__p__XK05.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK05
            ? toDetail(1, 'XK05', 'DB1M__p__XK05', '', 'XK05运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo6"
        :style="{
          background:
            infoList.DB1M__p__MAN05 && infoList.DB1M__p__MAN05.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN05
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN05',
                'MAN05_RM_GZQ',
                '热水温手操器'
              )
            : ''
        "
      >
        A
      </div>
      <div class="Mdou Mdou1"></div>
      <div class="Mdou Mdou2"></div>
      <div class="Mdou Mdou3"></div>
      <div class="Mdou Mdou4"></div>
      <div class="Mdou Mdou5"></div>
    </div>
    <div class="dingwei2">
      <p class="wenzi wenzi6">JRCA-0701C</p>
      <p class="wenzi wenzi7">FI-0703</p>
      <p class="wenzi wenzi8">PI-0709</p>
      <p class="wenzi wenzi9">PI-0707</p>
      <p class="wenzi wenzi10">PI-0706</p>
      <p class="wenzi wenzi11">PI-0711</p>
      <p class="wenzi wenzi12">FI-0705</p>
      <p
        class="shuzi shuzi11"
        @click="
          toCompon(
            2,
            'VP05',
            'DB1S',
            'VP05_GZQ',
            '阀门开度--流化床3#离心机进料阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP05, 'VP05', 'DB1S', 'VP05_GZQ')"
      >
        {{ infoList.DB1S.VP05 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi12"
        @click="
          toCompon(2, 'NI05', 'DB1S', 'NI05_GZQ', '流化床3#离心机进料功率')
        "
        @dblclick="Cclick(infoList.DB1S.NI05, 'NI05', 'DB1S', 'NI05_GZQ')"
      >
        {{ infoList.DB1S.NI05 || "0.00" }} KW
      </p>
      <p
        class="shuzi shuzi13"
        @click="toCompon(2, 'FT07', 'DB1S', 'FT07_GZQ', '流化床出料风送风量')"
        @dblclick="Cclick(infoList.DB1S.FT07, 'FT07', 'DB1S', 'FT07_GZQ')"
      >
        {{ infoList.DB1S.FT07 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi14"
        @click="
          toCompon(2, 'PT09', 'DB1S', 'PT09_GZQ', '流化床1#排风机入口压力')
        "
        @dblclick="Cclick(infoList.DB1S.PT09, 'PT09', 'DB1S', 'PT09_GZQ')"
      >
        {{ infoList.DB1S.PT09 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi15"
        @click="toCompon(2, 'PT08', 'DB1S', 'PT08_GZQ', '流化床活塞段压力')"
        @dblclick="Cclick(infoList.DB1S.PT08, 'PT08', 'DB1S', 'PT08_GZQ')"
      >
        {{ infoList.DB1S.PT08 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi16"
        @click="toCompon(2, 'PT07', 'DB1S', 'PT07_GZQ', '流化床混料段压力')"
        @dblclick="Cclick(infoList.DB1S.PT07, 'PT07', 'DB1S', 'PT07_GZQ')"
      >
        {{ infoList.DB1S.PT07 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi17"
        @click="
          toCompon(2, 'PT11', 'DB1S', 'PT11_GZQ', '流化床2#排风机入口压力')
        "
        @dblclick="Cclick(infoList.DB1S.PT11, 'PT11', 'DB1S', 'PT11_GZQ')"
      >
        {{ infoList.DB1S.PT11 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi18"
        @click="toCompon(2, 'FT06', 'DB1S', 'FT06_GZQ', '流化床流化排湿风量')"
        @dblclick="Cclick(infoList.DB1S.FT06, 'FT06', 'DB1S', 'FT06_GZQ')"
      >
        {{ infoList.DB1S.FT06 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi19"
        @click="
          toCompon(
            2,
            'VP10',
            'DB1S',
            'VP10_GZQ',
            '变频开度--流化床活塞段引风风量'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP10, 'VP10', 'DB1S', 'VP10_GZQ')"
      >
        {{ infoList.DB1S.VP10 || "0.00" }} %
      </p>
      <p class="biaoshi biaoshi2">C0703</p>
      <div
        class="caozuo caozuo7"
        :style="{
          background:
            (infoList.DB1M__p__XK12 && infoList.DB1M__p__XK12.RM == 1) ||
            (infoList.DB1M__p__XK12 && infoList.DB1M__p__XK12.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK12
            ? toDetail(1, 'XK12', 'DB1M__p__XK12', '', 'XK12运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo8"
        :style="{
          background:
            infoList.DB1M__p__MAN12 && infoList.DB1M__p__MAN12.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN12
            ? toCompon(0, 'AV', 'DB1M__p__MAN12', 'MAN12_RM_GZQ', '备用')
            : ''
        "
      >
        A
      </div>
      <div
        class="caozuo caozuo9"
        :style="{
          background:
            (infoList.DB1M__p__XK10 && infoList.DB1M__p__XK10.RM == 1) ||
            (infoList.DB1M__p__XK10 && infoList.DB1M__p__XK10.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK10
            ? toDetail(1, 'XK10', 'DB1M__p__XK10', '', 'XK10运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo10"
        :style="{
          background:
            infoList.DB1M__p__MAN10 && infoList.DB1M__p__MAN10.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN10
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN10',
                'MAN10_RM_GZQ',
                '1#旋转下料器手操器'
              )
            : ''
        "
      >
        A
      </div>
      <div class="Mdou Mdou6"></div>
    </div>
    <div class="dingwei3">
      <p class="wenzi wenzi13">DN100</p>
      <p class="wenzi wenzi14">LI-0701</p>
      <p class="wenzi wenzi15">TRC-0702</p>
      <p class="wenzi wenzi16">FI-0704</p>
      <p class="wenzi wenzi17">PI-SC01</p>
      <p class="wenzi wenzi18">DN200</p>
      <p class="wenzi wenzi19">PIC-0702</p>
      <p class="wenzi wenzi20">DN50</p>
      <p class="wenzi wenzi21">FI-0706</p>
      <p class="wenzi wenzi22">FIQ-0706</p>
      <p class="wenzi wenzi23">TRC-0701</p>
      <p class="wenzi wenzi24">TI-0703</p>
      <p class="wenzi wenzi25">PI-0704</p>
      <p class="wenzi wenzi26">FI-0701</p>
      <p class="wenzi wenzi27">PI-0703</p>
      <p
        class="shuzi shuzi20"
        @click="toCompon(2, 'PT01', 'DB1S', 'PT01_GZQ', '蒸汽总管压力')"
        @dblclick="Cclick(infoList.DB1S.PT01, 'PT01', 'DB1S', 'PT01_GZQ')"
      >
        {{ infoList.DB1S.PT01 || "0.00" }} Mpa
      </p>
      <p
        class="shuzi shuzi21"
        @click="
          toCompon(
            2,
            'VP07',
            'DB1S',
            'VP07_GZQ',
            '阀门开度--流化床热水温度调节阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP07, 'VP07', 'DB1S', 'VP07_GZQ')"
      >
        {{ infoList.DB1S.VP07 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi22"
        @click="toCompon(2, 'PT02', 'DB1S', 'PT02_GZQ', '流化床进床蒸汽压力')"
        @dblclick="Cclick(infoList.DB1S.PT02, 'PT02', 'DB1S', 'PT02_GZQ')"
      >
        {{ infoList.DB1S.PT02 || "0.00" }} Mpa
      </p>
      <p
        class="shuzi shuzi23"
        @click="
          toCompon(
            2,
            'VP11',
            'DB1S',
            'VP11_GZQ',
            '阀门开度--流化床进床蒸汽压力调节阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP11, 'VP11', 'DB1S', 'VP11_GZQ')"
      >
        {{ infoList.DB1S.VP11 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi24"
        @click="toCompon(2, 'LT03', 'DB1S', 'LT03_GZQ', '流化床补水罐液位')"
        @dblclick="Cclick(infoList.DB1S.LT03, 'LT03', 'DB1S', 'LT03_GZQ')"
      >
        {{ infoList.DB1S.LT03 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi25"
        @click="toCompon(2, 'TE02', 'DB1S', 'TE02_GZQ', '流化床热水温度')"
        @dblclick="Cclick(infoList.DB1S.TE02, 'TE02', 'DB1S', 'TE02_GZQ')"
      >
        {{ infoList.DB1S.TE02 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi26"
        @click="toCompon(2, 'FT05', 'DB1S', 'FT05_GZQ', '流化床循环热水流量')"
        @dblclick="Cclick(infoList.DB1S.FT05, 'FT05', 'DB1S', 'FT05_GZQ')"
      >
        {{ infoList.DB1S.FT05 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi27"
        @click="
          toCompon(
            2,
            'VP08',
            'DB1S',
            'VP08_GZQ',
            '阀门开度--流化床进床热风温度调节阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP08, 'VP08', 'DB1S', 'VP08_GZQ')"
      >
        {{ infoList.DB1S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi28"
        @click="toCompon(2, 'FT01', 'DB1S', 'FT01_GZQ', '流化床进床蒸汽流量')"
        @dblclick="Cclick(infoList.DB1S.FT01, 'FT01', 'DB1S', 'FT01_GZQ')"
      >
        {{ infoList.DB1S.FT01 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi29"
        @click="toCompon(2, 'FT02', 'DB1S', 'FT02_GZQ', '流化床进床流量累积')"
        @dblclick="Cclick(infoList.DB1S.FT02, 'FT02', 'DB1S', 'FT02_GZQ')"
      >
        {{ infoList.DB1S.FT02 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi30"
        @click="toCompon(2, 'TE01', 'DB1S', 'TE01_GZQ', '流化床进床热风温度')"
        @dblclick="Cclick(infoList.DB1S.TE01, 'TE01', 'DB1S', 'TE01_GZQ')"
      >
        {{ infoList.DB1S.TE01 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi31"
        @click="
          toCompon(2, 'TE03', 'DB1S', 'TE03_GZQ', '流化床混料段1、2室底部温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE03, 'TE03', 'DB1S', 'TE03_GZQ')"
      >
        {{ infoList.DB1S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi32"
        @click="toCompon(2, 'PT05', 'DB1S', 'PT05_GZQ', '流化床混料段料层压差')"
        @dblclick="Cclick(infoList.DB1S.PT05, 'PT05', 'DB1S', 'PT05_GZQ')"
      >
        {{ infoList.DB1S.PT05 || "0.00" }} MPa
      </p>
      <p
        class="shuzi shuzi33"
        @click="toCompon(2, 'FT03', 'DB1S', 'FT03_GZQ', '流化床返混风量')"
        @dblclick="Cclick(infoList.DB1S.FT03, 'FT03', 'DB1S', 'FT03_GZQ')"
      >
        {{ infoList.DB1S.FT03 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi34"
        @click="toCompon(2, 'PT03', 'DB1S', 'PT03_GZQ', '流化床混料段进风风压')"
        @dblclick="Cclick(infoList.DB1S.PT03, 'PT03', 'DB1S', 'PT03_GZQ')"
      >
        {{ infoList.DB1S.PT03 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi35"
        @click="
          toCompon(2, 'VP09', 'DB1S', 'VP09_GZQ', '变频开度--流化床混料段风量')
        "
        @dblclick="Cclick(infoList.DB1S.VP09, 'VP09', 'DB1S', 'VP09_GZQ')"
      >
        {{ infoList.DB1S.VP09 || "0.00" }} %
      </p>
      <p class="biaoshi biaoshi3">TK-4G</p>
      <p class="biaoshi biaoshi4">P0701A</p>
      <p class="biaoshi biaoshi5">P0701B</p>
      <p class="biaoshi biaoshi6">总管</p>
      <p class="biaoshi biaoshi7">C0701</p>
      <p class="biaoshi biaoshi8">过滤器</p>
      <p class="biaoshi biaoshi9">空气</p>
      <p class="biaoshi biaoshi10">V0701</p>
      <p class="biaoshi biaoshi11">A</p>
      <div
        class="caozuo caozuo11"
        :style="{
          background:
            (infoList.DB1M__p__XK09 && infoList.DB1M__p__XK09.RM == 1) ||
            (infoList.DB1M__p__XK09 && infoList.DB1M__p__XK09.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK09
            ? toDetail(1, 'XK09', 'DB1M__p__XK09', '', 'XK09运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo12"
        :style="{
          background:
            infoList.DB1M__p__MAN09 && infoList.DB1M__p__MAN09.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN09
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN09',
                'MAN09_RM_GZQ',
                '活塞风温手操器'
              )
            : ''
        "
      >
        A
      </div>
      <div
        class="caozuo caozuo13"
        :style="{
          background:
            (infoList.DB1M__p__XK20 && infoList.DB1M__p__XK20.RM == 1) ||
            (infoList.DB1M__p__XK20 && infoList.DB1M__p__XK20.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK20
            ? toDetail(1, 'XK20', 'DB1M__p__XK20', '', 'XK20运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo14"
        :style="{
          background:
            (infoList.DB1M__p__XK11 && infoList.DB1M__p__XK11.RM == 1) ||
            (infoList.DB1M__p__XK11 && infoList.DB1M__p__XK11.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK11
            ? toDetail(1, 'XK11', 'DB1M__p__XK11', '', 'XK11运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo15"
        :style="{
          background:
            infoList.DB1M__p__MAN11 && infoList.DB1M__p__MAN11.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN11
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN11',
                'MAN11_RM_GZQ',
                '2#旋转下料器手操器'
              )
            : ''
        "
      >
        A
      </div>
      <div
        class="caozuo caozuo16"
        :style="{
          background:
            (infoList.DB1M__p__XK19 && infoList.DB1M__p__XK19.RM == 1) ||
            (infoList.DB1M__p__XK19 && infoList.DB1M__p__XK19.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK19
            ? toDetail(1, 'XK19', 'DB1M__p__XK19', '', 'XK19运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo17"
        :style="{
          background:
            (infoList.DB1M__p__XK07 && infoList.DB1M__p__XK07.RM == 1) ||
            (infoList.DB1M__p__XK07 && infoList.DB1M__p__XK07.RM == 2)
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__XK07
            ? toDetail(1, 'XK07', 'DB1M__p__XK07', '', 'XK07运行方式')
            : ''
        "
      >
        X
      </div>
      <div
        class="caozuo caozuo18"
        :style="{
          background:
            infoList.DB1M__p__MAN07 && infoList.DB1M__p__MAN07.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN07
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN07',
                'MAN07_RM_GZQ',
                '混料风温手操器'
              )
            : ''
        "
      >
        A
      </div>
      <div
        class="caozuo caozuo19"
        :style="{
          background:
            infoList.DB1M__p__QK04 && infoList.DB1M__p__QK04.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK04
            ? toDetail(2, 'QK04', 'DB1M__p__QK04', '', 'QK04跟踪开关')
            : ''
        "
      >
        Q
      </div>
      <div
        class="caozuo caozuo20"
        :style="{
          background:
            infoList.DB1M__p__QK03 && infoList.DB1M__p__QK03.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK03
            ? toDetail(2, 'QK03', 'DB1M__p__QK03', '', 'QK03跟踪开关')
            : ''
        "
      >
        Q
      </div>
      <div
        class="caozuo caozuo21"
        :style="{
          background:
            infoList.DB1M__p__QK02 && infoList.DB1M__p__QK02.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK02
            ? toDetail(2, 'QK02', 'DB1M__p__QK02', '', 'QK02跟踪开关')
            : ''
        "
      >
        Q
      </div>
      <div
        class="caozuo caozuo22"
        :style="{
          background:
            infoList.DB1M__p__QK01 && infoList.DB1M__p__QK01.TS != 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__QK01
            ? toDetail(2, 'QK01', 'DB1M__p__QK01', '', 'QK01跟踪开关')
            : ''
        "
      >
        Q
      </div>
    </div>
    <div class="dingwei4">
      <p class="wenzi wenzi28">TI-0704</p>
      <p class="wenzi wenzi29">TI-0705</p>
      <p class="wenzi wenzi30">TI-0706</p>
      <p class="wenzi wenzi31">TI-0707</p>
      <p class="wenzi wenzi32">TI-0703</p>
      <p class="wenzi wenzi33">PI-0705</p>
      <p class="wenzi wenzi34">FI-0702</p>
      <p class="wenzi wenzi35">PI-0708</p>
      <p class="wenzi wenzi36">PI-0710</p>
      <p class="wenzi wenzi37">TI-0709</p>
      <p class="wenzi wenzi38">FI-0707</p>
      <p class="wenzi wenzi39">FQI-0707</p>
      <p class="wenzi wenzi40">冷却水流量累计</p>
      <p
        class="shuzi shuzi36"
        @click="
          toCompon(2, 'TE04', 'DB1S', 'TE04_GZQ', '流化床混料段3室底部温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE04, 'TE04', 'DB1S', 'TE04_GZQ')"
      >
        {{ infoList.DB1S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi37"
        @click="
          toCompon(2, 'TE05', 'DB1S', 'TE05_GZQ', '流化床混料段4室底部温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE05, 'TE05', 'DB1S', 'TE05_GZQ')"
      >
        {{ infoList.DB1S.TE05 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi38"
        @click="
          toCompon(2, 'TE06', 'DB1S', 'TE06_GZQ', '流化床混料段5室底部温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE06, 'TE06', 'DB1S', 'TE06_GZQ')"
      >
        {{ infoList.DB1S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi39"
        @click="
          toCompon(2, 'TE07', 'DB1S', 'TE07_GZQ', '流化床混料段6室底部温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE07, 'TE07', 'DB1S', 'TE07_GZQ')"
      >
        {{ infoList.DB1S.TE07 || "0.00"  }} ℃
      </p>
      <p
        class="shuzi shuzi40"
        @click="
          toCompon(2, 'TE08', 'DB1S', 'TE08_GZQ', '流化床冷却循环水进水温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE08, 'TE08', 'DB1S', 'TE08_GZQ')"
      >
        {{ infoList.DB1S.TE08 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi41"
        @click="toCompon(2, 'PT06', 'DB1S', 'PT06_GZQ', '流化床活塞段料层压差')"
        @dblclick="Cclick(infoList.DB1S.PT06, 'PT06', 'DB1S', 'PT06_GZQ')"
      >
        {{ infoList.DB1S.PT06 || "0.00" }} MPa
      </p>
      <p
        class="shuzi shuzi42"
        @click="toCompon(2, 'FT04', 'DB1S', 'FT04_GZQ', '流化床活塞风量')"
        @dblclick="Cclick(infoList.DB1S.FT04, 'FT04', 'DB1S', 'FT04_GZQ')"
      >
        {{ infoList.DB1S.FT04 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi43"
        @click="toCompon(2, 'PT03', 'DB1S', 'PT03_GZQ', '流化床混料段进风风压')"
        @dblclick="Cclick(infoList.DB1S.PT03, 'PT03', 'DB1S', 'PT03_GZQ')"
      >
        {{ infoList.DB1S.PT03 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi44"
        @click="
          toCompon(2, 'VP12', 'DB1S', 'VP12_GZQ', '阀门开度--流化床旋转下料器')
        "
        @dblclick="Cclick(infoList.DB1S.VP12, 'VP12', 'DB1S', 'VP12_GZQ')"
      >
        {{ infoList.DB1S.VP12 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi45"
        @click="toCompon(2, 'PT10', 'DB1S', 'PT10_GZQ', '流化床风送压力')"
        @dblclick="Cclick(infoList.DB1S.PT10, 'PT10', 'DB1S', 'PT10_GZQ')"
      >
        {{ infoList.DB1S.PT10 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi46"
        @click="
          toCompon(2, 'TE09', 'DB1S', 'TE09_GZQ', '流化床冷却循环水回水温度')
        "
        @dblclick="Cclick(infoList.DB1S.TE09, 'TE09', 'DB1S', 'TE09_GZQ')"
      >
        {{ infoList.DB1S.TE09 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi47"
        @click="toCompon(2, 'FT08', 'DB1S', 'FT08_GZQ', '流化床冷却水流量')"
        @dblclick="Cclick(infoList.DB1S.FT08, 'FT08', 'DB1S', 'FT08_GZQ')"
      >
        {{ infoList.DB1S.FT08 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi48"
        @click="toCompon(2, 'FT09', 'DB1S', 'FT09_GZQ', '流化床冷却水流量累积')"
        @dblclick="Cclick(infoList.DB1S.FT09, 'FT09', 'DB1S', 'FT09_GZQ')"
      >
        {{ infoList.DB1S.FT09 || "0.00" }} m³/h
      </p>
      <!-- <p class="shuzi shuzi"></p> -->
      <p class="biaoshi biaoshi12">过滤器</p>
      <p class="biaoshi biaoshi13">V0702</p>
      <p class="biaoshi biaoshi14">RV0702</p>
      <p class="biaoshi biaoshi15">风送</p>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Gzyx",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  // filters: {
  //   // 阀门红绿灯
  //   typeFifter: (val) => {
  //     if (val < 5) {
  //       return require("@/assets/images/截止阀.png");
  //     } else if (val >= 5) {
  //       return require("@/assets/images/截止阀2.png");
  //     }
  //   },
  //   //釜内红绿灯
  //   tyFifter: (val) => {
  //     if (val <= 10) {
  //       return require("@/assets/images/关闭.png");
  //     } else if (val > 10) {
  //       return require("@/assets/images/开始.png");
  //     }
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 90%;
  height: 84vh;
  margin-left: 5vw;
  margin-top: 1vh;
  background-image: url("~@/assets/images/干燥流化床-永祥.png");
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  .dingwei1,
  .dingwei2,
  .dingwei3,
  .dingwei4 {
    position: relative;
    font-size: 0.8vw;
    width: 50%;
    height: 50%;
    color: #fff;
    // border: 1px solid #fff;
    .wenzi {
      position: absolute;
    }
    .shuzi {
      position: absolute;
      font-size: 0.7vw;cursor: pointer;
    }
    .fen {
      color: #ff09db;
    }
    .biaoshi {
      position: absolute;
      font-weight: 700;
      font-size: 1vw;
    }
    .caozuo {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2afc30;
      color: #fff;
      width: 1vw;
      height: 2vh;
      box-sizing: border-box;
      border: 1px solid #fdf061;
      font-size: 0.8vw;
      cursor: pointer;
      z-index: 11; /* 提高层级 */
    }
    .wenzi1 {
      top: 0vh;
      left: 6.8vw;
    }
    .wenzi2 {
      top: 13vh;
      left: 3.8vw;
    }
    .wenzi3 {
      bottom: 8vh;
      left: 3.8vw;
    }
    .wenzi4 {
      top: 12vh;
      left: 24.5vw;
    }
    .wenzi5 {
      top: 12vh;
      right: 5vw;
    }
    .shuzi1 {
      top: 2vh;
      left: 6.8vw;
    }
    .shuzi2 {
      top: 0.3vh;
      left: 12.8vw;
    }
    .shuzi3 {
      top: 15.5vh;
      left: 3.8vw;
    }
    .shuzi4 {
      bottom: 6vh;
      left: 3.8vw;
    }
    .shuzi5 {
      top: 10vh;
      left: 16vw;
    }
    .shuzi6 {
      top: 12vh;
      left: 16vw;
    }
    .shuzi7 {
      top: 5vh;
      left: 24.5vw;
    }
    .shuzi8 {
      top: 10.5vh;
      left: 24.5vw;
    }
    .shuzi9 {
      top: 5vh;
      left: 35.4vw;
    }
    .shuzi10 {
      top: 10.5vh;
      left: 35.4vw;
    }
    .biaoshi1 {
      top: 23.7vh;
      left: 8vw;
    }
    .caozuo1 {
      top: 8vh;
      left: 19vw;
    }
    .caozuo2 {
      top: 8vh;
      left: 20.5vw;
    }
    .caozuo3 {
      top: 8vh;
      right: 14.5vw;
    }
    .caozuo4 {
      top: 8vh;
      right: 12.9vw;
    }
    .caozuo5 {
      top: 8vh;
      right: 4vw;
    }
    .caozuo6 {
      top: 8vh;
      right: 2.5vw;
    }
    .Mdou {
      position: absolute;
      width: 1.5vw;
      height: 3vh;
      background-image: url("~@/assets/images/M.png");
      background-size: 100% 100%;
    }
    .Mdou1 {
      bottom: 9.5vh;
      left: 19.5vw;
    }
    .Mdou2 {
      bottom: 9.5vh;
      left: 24vw;
    }
    .Mdou3 {
      bottom: 9.5vh;
      right: 13.5vw;
    }
    .Mdou4 {
      bottom: 9.5vh;
      right: 8.7vw;
    }
    .Mdou5 {
      bottom: 9.5vh;
      right: 3.2vw;
    }
    .Mdou6 {
      bottom: 9.5vh;
      left: 0vw;
    }
    .wenzi6 {
      top: 12vh;
      left: 0.5vw;
    }
    .wenzi7 {
      top: 9vh;
      left: 9vw;
    }
    .wenzi8 {
      top: 2vh;
      left: 14vw;
    }
    .wenzi9 {
      bottom: 11vh;
      left: 6.5vw;
    }
    .wenzi10 {
      color: #000;
      bottom: 0vh;
      left: 4.5vw;
    }
    .wenzi11 {
      top: 9vh;
      right: 8vw;
    }
    .wenzi12 {
      top: 14.5vh;
      right: 12vw;
    }
    .shuzi11 {
      top: 5vh;
      left: 0.5vw;
    }
    .shuzi12 {
      top: 10.5vh;
      left: 0.5vw;
    }
    .shuzi13 {
      top: 11vh;
      left: 9vw;
    }
    .shuzi14 {
      top: 4vh;
      left: 14vw;
    }
    .shuzi15 {
      bottom: 9.3vh;
      left: 6.5vw;
    }
    .shuzi16 {
      color: #000;
      bottom: -2vh;
      left: 4.5vw;
    }
    .shuzi17 {
      top: 11vh;
      right: 8vw;
    }
    .shuzi18 {
      top: 16.5vh;
      right: 12vw;
    }
    .shuzi19 {
      top: 17.5vh;
      right: 3vw;
    }
    .biaoshi2 {
      top: 9.5vh;
      left: 18.5vw;
    }
    .caozuo7 {
      bottom: 0vh;
      right: 23.5vw;
    }
    .caozuo8 {
      bottom: 0vh;
      right: 22vw;
    }
    .caozuo9 {
      top: 22vh;
      right: 4.5vw;
    }
    .caozuo10 {
      top: 22vh;
      right: 3vw;
    }
    .wenzi13 {
      top: 7vh;
      left: 6.5vw;
    }
    .wenzi14 {
      top: 4vh;
      left: 18vw;
    }
    .wenzi15 {
      top: 12.7vh;
      left: 17.8vw;
    }
    .wenzi16 {
      top: 11.7vh;
      left: 29vw;
    }
    .wenzi17 {
      top: 13vh;
      left: 0.5vw;
    }
    .wenzi18 {
      bottom: 9vh;
      left: 0.5vw;
    }
    .wenzi19 {
      bottom: 17vh;
      left: 6vw;
    }
    .wenzi20 {
      bottom: 15vh;
      left: 13.5vw;
    }
    .wenzi21 {
      bottom: 17vh;
      left: 21vw;
    }
    .wenzi22 {
      bottom: 17vh;
      left: 28vw;
    }
    .wenzi23 {
      bottom: 21vh;
      right: 6vw;
    }
    .wenzi24 {
      color: #000;
      top: 6vh;
      right: 2.5vw;
    }
    .wenzi25 {
      color: #000;
      top: 12vh;
      right: 2vw;
    }
    .wenzi26 {
      bottom: 6vh;
      right: 5.5vw;
    }
    .wenzi27 {
      bottom: -0.3vh;
      right: 5.5vw;
    }
    .shuzi20 {
      top: 15vh;
      left: 0.5vw;
    }
    .shuzi21 {
      top: 13.5vh;
      left: 9vw;
    }
    .shuzi22 {
      bottom: 15vh;
      left: 6vw;
    }
    .shuzi23 {
      bottom: 11vh;
      left: 0.5vw;
    }
    .shuzi24 {
      top: 6.5vh;
      left: 18vw;
    }
    .shuzi25 {
      top: 10.7vh;
      left: 18vw;
    }
    .shuzi26 {
      top: 14vh;
      left: 29vw;
    }
    .shuzi27 {
      bottom: 11vh;
      left: 17.8vw;
    }
    .shuzi28 {
      bottom: 15vh;
      left: 21vw;
    }
    .shuzi29 {
      bottom: 15vh;
      left: 28vw;
    }
    .shuzi30 {
      bottom: 19vh;
      right: 6.5vw;
    }
    .shuzi31 {
      color: #000;
      top: 8vh;
      right: 2.5vw;
    }
    .shuzi32 {
      color: #000;
      top: 14vh;
      right: 2vw;
    }
    .shuzi33 {
      bottom: 4vh;
      left: 36.6vw;
    }
    .shuzi34 {
      bottom: 2vh;
      left: 36.6vw;
    }
    .shuzi35 {
      bottom: 3.5vh;
      left: 23.6vw;
    }
    .biaoshi3 {
      top: -0.5vh;
      left: 7.9vw;
    }
    .biaoshi4 {
      top: -1vh;
      left: 24vw;
    }
    .biaoshi5 {
      top: 13vh;
      left: 24vw;
    }
    .biaoshi6 {
      top: 10vh;
      left: 0.9vw;
    }
    .biaoshi10 {
      top: 16.9vh;
      left: 0.5vw;
    }
    .biaoshi7 {
      bottom: 7.5vh;
      left: 19vw;
    }
    .biaoshi8 {
      bottom: 6.5vh;
      left: 13vw;
    }
    .biaoshi9 {
      bottom: 3.2vh;
      left: 7vw;
    }
    .biaoshi11 {
      top: 2.8vh;
      left: 3.5vw;
    }
    .caozuo11 {
      bottom: 4vh;
      right: 17vw;
    }
    .caozuo12 {
      bottom: 4vh;
      right: 15.5vw;
    }
    .caozuo13 {
      bottom: 22vh;
      right: 27vw;
    }
    .caozuo14 {
      bottom: 22vh;
      right: 25.5vw;
    }
    .caozuo15 {
      bottom: 22vh;
      right: 24vw;
    }
    .caozuo16 {
      top: 6vh;
      right: 13vw;
    }
    .caozuo17 {
      top: 6vh;
      right: 11.5vw;
    }
    .caozuo18 {
      top: 6vh;
      right: 10vw;
    }
    .caozuo19 {
      top: 9vh;
      right: 13vw;
    }
    .caozuo20 {
      top: 9vh;
      right: 11.5vw;
    }
    .caozuo21 {
      top: 9vh;
      right: 10vw;
    }
    .caozuo22 {
      top: 9vh;
      right: 8.5vw;
    }
    .biaoshi12 {
      bottom: 9vh;
      left: 2.8vw;
    }
    .biaoshi13 {
      bottom: 7vh;
      left: 11.5vw;
    }
    .biaoshi14 {
      bottom: 18vh;
      left: 19.5vw;
    }
    .biaoshi15 {
      top: 9vh;
      right: 4vw;
    }
    .wenzi28 {
      color: #000;
      top: 6vh;
      left: -1vw;
    }
    .wenzi29 {
      color: #000;
      top: 6vh;
      left: 3.5vw;
    }
    .wenzi30 {
      color: #000;
      top: 6vh;
      left: 8vw;
    }
    .wenzi31 {
      color: #000;
      top: 6vh;
      left: 16vw;
    }
    .wenzi32 {
      top: 6vh;
      left: 26vw;
    }
    .wenzi33 {
      color: #000;
      top: 12vh;
      left: 4.5vw;
    }
    .wenzi34 {
      bottom: 17vh;
      left: 9vw;
    }
    .wenzi35 {
      bottom: 11vh;
      left: 9vw;
    }
    .wenzi36 {
      bottom: 6vh;
      left: 20vw;
    }
    .wenzi37 {
      top: 15vh;
      right: 16vw;
    }
    .wenzi38 {
      bottom: 13vh;
      left: 35vw;
    }
    .wenzi39 {
      bottom: 6.8vh;
      left: 35vw;
    }
    .wenzi40 {
      bottom: 16vh;
      left: 35vw;
    }
    .shuzi36 {
      color: #000;
      top: 8vh;
      left: -1vw;
    }
    .shuzi37 {
      color: #000;
      top: 8vh;
      left: 3.5vw;
    }
    .shuzi38 {
      color: #000;
      top: 8vh;
      left: 8vw;
    }
    .shuzi39 {
      color: #000;
      top: 8vh;
      left: 16vw;
    }
    .shuzi40 {
      top: 8vh;
      left: 26vw;
    }
    .shuzi41 {
      color: #000;
      top: 14vh;
      left: 4.5vw;
    }
    .shuzi42 {
      bottom: 15vh;
      left: 9vw;
    }
    .shuzi43 {
      bottom: 13.2vh;
      left: 9vw;
    }
    .shuzi44 {
      bottom: 16vh;
      left: 20vw;
    }
    .shuzi45 {
      bottom: 4vh;
      left: 20vw;
    }
    .shuzi46 {
      top: 17vh;
      right: 16vw;
    }
    .shuzi47 {
      bottom: 11vh;
      left: 35vw;
    }
    .shuzi48 {
      bottom: 9vh;
      left: 35vw;
    }
  }
}
</style>